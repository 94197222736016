<template>
  <div class="affairs">
    <TopNavBar2 />
    <Carousel :carousels="carousels" />
    <div class="box">
      <div class="framework_box">
        <Title title="架构图" />
        <div class="framework">
          <img src="@/assets/images/product/affairs/bg1.png" alt="" />
        </div>
      </div>
    </div>
    <div class="application_box">
      <Title title="政务场景应用" />
      <img src="@/assets/images/product/affairs/twitter.png" alt="" />
      <!-- <div class="application" id="container"></div>
      <div class="middle">
        <div>智慧政务</div>
      </div> -->
    </div>
    <Footer2 />
  </div>
</template>

<script>
// import Js2WordCloud from "js2wordcloud";
export default {
  name: "Affairs",
  data() {
    return {
      carousels: [
        {
          imgUrl: require("@/assets/images/carousels/11.png"),
        },
      ],
    };
  },
  mounted() {
    // let wc = new Js2WordCloud(document.getElementById("container"));
    // wc.setOption({
    //   imageShape: require("@/assets/images/product/affairs/twitter.png"),
    //   list: [
    //     ["医疗器械企业主体责任落实系统"],
    //     ["农渔业产业链数字化监控"],
    //     ["数字化改革门户建设"],
    //     ["数字经信企业+产业服务平台"],
    //     ["数字化金融知识教育与宣传平台"],
    //     ["市场安全综合监管系统"],
    //     ["城市运行管理服务平台"],
    //     ["一码通平台配套服务"],
    //     ["医疗器械网销监管"],
    //     ["数字辨妆—网销化妆品监测沙盘"],
    //     ["电力无人营业厅软件系统"],
    //     ["事业单位法人浙里看"],
    //     ["电力营销知识库"],
    //     ["环境保护管家小助手"],
    //     ["数字平台综合项目出租房模块"],
    //     ["数字化经信产业数据管理"],
    //     ["数字化市场监管"],
    //     ["疫情大物防监管"],
    //     ["自主资源数字化管理"],
    //     ["药品流通“黑匣子”中间库建设"],
    //   ],
    //   shape: "pentagon",
    //   ellipticity: 1,
    // });
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.affairs {
  .box {
    display: flex;
    justify-content: center;
  }
  .framework_box {
    margin-top: 20px;
    .framework {
      margin-top: 20px;
      width: 345px;
      height: 194px;
      background-color: #ffffff;
      box-shadow: 0px 0px 30px #eeeeeeb3;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 325px;
        height: 172px;
      }
    }
  }
  .application_box {
    position: relative;
    margin-top: 25px;
    padding: 20px 0 0;
    height: 420px;
    background: linear-gradient(
      220.440049912588deg,
      #ffffff 0%,
      #ddeaff 55%,
      #ffffff 100%
    );
    > img {
      margin: 15px auto 0;
      width: 355px;
      height: 355px;
      border-radius: 50%;
      overflow: hidden;
    }
    // .application {
    //   margin: 15px auto 0;
    //   width: 355px;
    //   height: 355px;
    //   border-radius: 50%;
    //   overflow: hidden;
    // }
    // .middle {
    //   position: absolute;
    //   width: 85px;
    //   height: 85px;
    //   left: 50%;
    //   top: 55%;
    //   transform: translate(-50%, -50%);
    //   background-image: url(~@/assets/images/product/affairs/bg2.svg);
    //   background-repeat: no-repeat;
    //   background-size: cover;
    //   background-position: center center;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   > div {
    //     width: 35px;
    //     height: 65px;
    //     background-image: url(~@/assets/images/product/affairs/bg3.svg);
    //     background-repeat: no-repeat;
    //     background-size: cover;
    //     font-size: 15px;
    //     color: #fff;
    //     padding: 0 15px;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //   }
    // }
  }
}
</style>
