import { render, staticRenderFns } from "./Affairs.vue?vue&type=template&id=7175367c&scoped=true&"
import script from "./Affairs.vue?vue&type=script&lang=js&"
export * from "./Affairs.vue?vue&type=script&lang=js&"
import style0 from "./Affairs.vue?vue&type=style&index=0&id=7175367c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7175367c",
  null
  
)

export default component.exports